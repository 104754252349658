@import "partials/color";
@import "partials/fonts";
@import "partials/base";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/mixins";

// 2. Include any default variable overrides here
$container-max-widths: (
  xl: 1440px
) !default;

$theme-colors: (
  "primary": $primary,
  "primary-op": $primary-op,
  "lightPrimary": $lightPrimary,
  "light": $light,
  "lightGray": $lightGray,
  "secondary": $secondary,
);


//Base
$body-bg: #fff;
$body-color: $dark;
$font-family-base: $O;

//Sizes
$h1-font-size: 60px;
$h2-font-size: 35px;
$h3-font-size: 28px;
$h4-font-size: 1.125rem; //18
$h5-font-size: 1rem; //16
$h6-font-size: 0.9375rem; //15


//Cards
$card-height: 100%;

//modal
$modal-content-border-color:        rgba($black, 0) !default;
$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;
$modal-content-border-radius:       $border-radius !default;



// 3. Include remainder of required Bootstrap stylesheets
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/root";
@import "/node_modules/bootstrap/scss/reboot";
@import "/node_modules/bootstrap/scss/type";
@import "/node_modules/bootstrap/scss/images";
@import "/node_modules/bootstrap/scss/code";
@import "/node_modules/bootstrap/scss/grid";
@import "/node_modules/bootstrap/scss/tables";
@import "/node_modules/bootstrap/scss/forms";
@import "/node_modules/bootstrap/scss/buttons";
@import "/node_modules/bootstrap/scss/transitions";
@import "/node_modules/bootstrap/scss/dropdown";
@import "/node_modules/bootstrap/scss/button-group";
@import "/node_modules/bootstrap/scss/input-group";
@import "/node_modules/bootstrap/scss/custom-forms";
@import "/node_modules/bootstrap/scss/nav";
@import "/node_modules/bootstrap/scss/navbar";
@import "/node_modules/bootstrap/scss/card";
@import "/node_modules/bootstrap/scss/breadcrumb";
@import "/node_modules/bootstrap/scss/pagination";
@import "/node_modules/bootstrap/scss/badge";
@import "/node_modules/bootstrap/scss/jumbotron";
@import "/node_modules/bootstrap/scss/alert";
@import "/node_modules/bootstrap/scss/progress";
@import "/node_modules/bootstrap/scss/media";
@import "/node_modules/bootstrap/scss/list-group";
@import "/node_modules/bootstrap/scss/close";
@import "/node_modules/bootstrap/scss/toasts";
@import "/node_modules/bootstrap/scss/modal";
@import "/node_modules/bootstrap/scss/tooltip";
@import "/node_modules/bootstrap/scss/popover";
@import "/node_modules/bootstrap/scss/carousel";
@import "/node_modules/bootstrap/scss/spinners";
@import "/node_modules/bootstrap/scss/utilities";
@import "/node_modules/bootstrap/scss/print";
//@import "node_modules/bootstrap/scss/bootstrap";


//Reboot
button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color;
}

//sup
sub,
sup {
  @include font-size(65%);
}
sup {
  top: -.4em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{font-weight: 400;}
