/*Variables globales*/
$BaseUrl: '../assets';
$spry: '#{$BaseUrl}/spry.png';
$height-header: 58px;
$height-footer: 72px;
$height-headers: $height-header + ($height-footer);

$border-radius:               0.375rem !default; //6
$border-radius-sm:            .2rem !default;//3
$border-radius-lg:            0.625rem !default;//10

/*clases generales*/
%relative {position: relative; }
%absolute{ position: absolute;}
%relativeBox {display:block; position: relative; width: 100%;}
%relativeInlineBox {display:inline-block; position: relative; width: auto;}
%absoluteBox {display:block; position: absolute; width: 100%;}
