// Import
@import "partials/fonts";
@import "partials/color";
@import "partials/mixins";
@import "partials/base";
//@import "multiselect";

//iconos, custom buttons, shapes
a{
	&:focus{outline:0; }
}
img{border: none;text-indent: -5000px;}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	line-height: 1.3;
}

.h1{
	&-lg{font-size: 4.1875rem;line-height: 80px;} //67
}

.h2{
  &-md{font-size: 2.25rem;} //36
  &-sm{font-size: 2rem;} //32
}

.h3{
  &-md{font-size: 1.5625rem;} //25
}

.h4{
	&-md{font-size: 1.5rem;line-height: 28px;} //24
	&-sm{font-size: 1rem;line-height: 20px;} //16
}

.h5{
	&-sm{font-size: 1rem; line-height: 22px;} //16
}

.p, .li{font-size: 0.9375rem; font-weight: normal; //15
	&-sm{font-size: 0.875rem; line-height: 1.4;} //14

}

p, li{font-size: 0.9375rem; line-height: 1.6; font-weight: normal; //15
	&.small{font-size: 0.75rem; line-height: 1.3;} //12
  b, strong{font-family: inherit; font-weight: 700;}
}

ul{list-style: none; list-style-type: none;
	li{list-style: none;list-style-type: none;}
  &.list-bullet-solidCircle{
    li{
      .ico-bullet{padding-left: 15px; position: relative;
        &:before{content: ''; width: 7px; height: 7px; @include bg-traslucid($black, 100);  @include border-radius(100%); margin-top: -3.5px; top: 50%; left:0; position: absolute;}
      }
    }
  }
}

.hr-heightSmall{border-width: .5px!important;}
.border-primary-opacity{border-color: rgba(0, 116, 196, .3)!important;}

body{
	.navbar-collapse{@include transition(all .5s ease);}
	&.header-active{ position: fixed; overflow: hidden;width: 100%;
	}
}

.shadow-focus-1{
  &:focus{box-shadow: 0 0 5px rgba(0,0,0, .15)!important;}
}
.lspace-none{letter-spacing: 0!important;}
.flex-0{flex: 0 0 0;}

.hover{
  &-unlin{
    &:hover{text-decoration: underline;}
  }
  &-op55{opacity: .55; @include transition(all .3s ease);
    &:hover{opacity: 1; @include transition(all .3s ease);}
  }
  &-op7{opacity: .7; @include transition(all .3s ease);
    &:hover{opacity: 1; @include transition(all .3s ease);}
  }
  &-op85{opacity: .85; @include transition(all .3s ease);
    &:hover{opacity: 1; @include transition(all .3s ease);}
  }
  &-op9{opacity: .9; @include transition(all .3s ease);
    &:hover{opacity: 1; @include transition(all .3s ease);}
  }
  &-salto{
    p, &>*{@include transform(translate(0, 0px)); @include transition(all .2s ease-out);}
    &:hover{
      p{@include transform(translate(0, -4px)); @include transition(all .2s ease-out);}
    }
  }
  &-moveLeft{
    &>*{@include transform(translate(0, 0px)); @include transition(transform .4s ease-out);}
    &:hover{
      &>*{@include transform(translate(-5px, 0)); @include transition(transform .3s ease-in-out);}
    }
  }
}

.flex-flow-row{flex-flow: row!important;}
.lspace-1{letter-spacing:0.1em;}
.lspace-75{letter-spacing: 0.034375rem;} //0.55
.text-underline{text-decoration: underline;}
.repeat-x{background-repeat: repeat-x;}
.repeat-y{background-repeat: repeat-y;}
.repeat{background-repeat: repeat;}
.shadow{box-shadow:1px 1px 5px 0 rgba(0,0,0, .07)!important;}
.bg-white-opacity{@include bg-traslucid($white, .55);}
.color-inherit{color: inherit;}
.rounded-top-0{border-top-left-radius: 0!important; border-top-right-radius: 0!important;}
.border-transparent{border-color: transparent!important;}
.overflow-visible{overflow: visible!important;}
.d{
  &-table-caption{display: table-caption;}
}
.flex{
  &-20{flex: 20%;}
}
.max-w{
  &-10{max-width: 10px;}
  &-15{max-width: 15px;}
  &-20{max-width: 20px;}
  &-30{max-width: 30px;}
  &-40{max-width: 40px;}
  &-120{max-width: 120px;}
  &-200{max-width: 200px;}
  &-220{max-width: 220px;}
  &-240{max-width: 240px;}
  &-initial{max-width: initial;}
}
.min-w{
  &-240{min-width: 240px;}
  &-260{min-width: 260px;}
  &-280{min-width: 280px;}
}

.min-h{
  &-240{min-height: 240px;}
  &-400{min-height: 400px;}
  &-500{min-height: 500px;}
}

.h{
  &-30{height: 30px;}
}

hr{
  &.large{height: 4px;}
}

.titleDeco{
  &-line{
    &-down{position: relative;
      &:before{content:''; left:50%; bottom:0; margin-left: -68px; width: 136px; height: 1px; @include bg-traslucid($dark, 100); position: absolute; }
    }
  }
  &.line-left{
    &:before{left:0; margin-left: 0;}
  }
  &.line-w60{
    &:before{width: 60px;}
  }
  &.line-opacity-25{
    &:before{opacity: .25;}
  }
  &.line-opacity-6{
    &:before{opacity: .6;}
  }
  &.hr-heightSmall{
    &:before{height: .5px!important;}
  }
}

//iconos
.ico{
	&-circle-left{display: block;
		svg,img{width: 30px!important; height: 30px!important;}
	}
  &-plus{display: block;
    svg,img{width: 14px!important; height: 14px!important;}
  }
  &-less{display: block;
    svg,img{width: 14px!important; height: 14px!important;}
  }
  &-trash{display: block;
    svg,img{width: 14px!important; height: 14px!important;}
  }
  &-close{
    &-circle{display: block;opacity: .8; @include transform(rotate(0deg)); @include transition(all .5s ease-in-out);
      svg,img{width: 25px!important; height: 25px!important;}
      &:hover{@include transform(rotate(360deg));opacity: 1;@include transition(all .4s ease);}
    }
    &-square{display: block; width: 38px; height: 38px;
      svg,img{position: absolute; width: 18px!important; height: 18px!important;left: 50%; top: 50%; @include transform(translate(-50%, -50%));}
    }
  }
  &-bg{width: 33px;height: 33px;position: relative; display: block;
    &-lg{width: 50px;height: 50px;}
  }
  &-triangle-right{position: relative;
    &:before{content:''; position: absolute;left: 0; top: 50%; margin-top: -5px; width: 0;height: 0;border-top: 5px solid transparent;border-bottom: 5px solid transparent;border-left: 8px solid $primary;}
  }
  &-back-circle{
    img{@include transform(scale(.7)); @include transition(all .5s ease-in-out); display: block;
      &:hover{@include transform(scale(.8)); @include transition(all .3s ease);}
    }
  }
}

.cursor{
  &-text{cursor: text!important;}
}

.border{
  &-light-opacity{border-color: rgba(255,255,255, .3);}
}

//btn
.btn{letter-spacing: 0.04375rem; position: relative;
  &.small{font-size: 0.75rem; letter-spacing: 0rem;}
  //&:before{content:''; position: absolute; @include bg-traslucid($white, 0.2); top:0; left:0; width: 0; overflow: hidden; height: 100%; @include transition(all .5s ease-in-out);}
	&-text{border: 1px solid transparent;font-family: $O; position: relative;//70
    //&:hover{color: $primary!important;}
    &-primary{border: 1px solid transparent; position: relative;
      &:hover{background-color: $primary; border-color: $primary; color: $white!important;}
    }
    &-secondary{border: 1px solid transparent; position: relative;
      &:hover{background-color: $secondary; border-color: $secondary; color: $white!important; }
    }
  }
  &[class*="btn-outline"]{
    &:hover{color: $white!important;}
  }
  &[class*="btn-outline-primary"]{
    &:disabled{color: $primary!important;}
  }
  &:hover{
    //&:before{width: 100%; @include bg-traslucid($white, .1); @include transition(all .3s ease-in-out);}
  }
}

header{z-index: 1000; position: relative; //@include box-shadow(0 5px 30px 0 rgba(0,0,0, .05));//height: $height-header;
  .navbar-nav{
    .nav-item{
      .nav-link{color: rgba(0,0,0, 100) !important; @include transition(all .4s ease); cursor: pointer!important;
        &.active, &:hover, &:focus{color: $primary !important; @include transition(all .3s ease);}
      }
    }
  }
  &.sticky{position: fixed; width: 100%; left:0; top:0; @include box-shadow(0 0 4px 1px rgba(0,0,0, .05));border-bottom: 1px solid $primary-op!important;
    nav{padding: 15px 1rem!important;
      .navbar-nav{
        .nav-item{font-size: 0.8125rem; line-height: 18px; //13px
          .nav-link{@include transition(all .2s ease);}
        }
      }
    }
    .navbar-brand img{max-width: 90px!important;}
  }
}

.border-width-2{border-width: 2px;}

footer{
  border-color: $lightGray3!important;
  &>.container{border-color: $lightGray3!important;}
  p{color: $gray2!important;}
  a{cursor: pointer!important;}
}

.navbar-brand{
  img{max-width: 200px;}
}
.cursor-pointer{cursor: pointer!important;}
.card{@include transition(all .4s ease-in-out);
  .card-body{
    &>*{
      &:last-child{margin-bottom: 0!important; /*border-bottom: none!important;*/
        &>.form-group{margin-bottom: 0!important;}
      }
    }
  }
  .btn-transactionType{right: 7px; top: 7px; z-index: 100;}
  &.shadow{}
  &.hover-shadow{ @include transition(all .4s ease);}
  &:hover{
    .btn{
      &.hover-moveLeft{
        &>*{@include transform(translate(-5px, 0)); @include transition(all .2s ease);}
      }
    }
    &.hover-shadow{
      @include box-shadow(0 0 7px 1px rgba(0, 0, 0, 0.08)!important); @include transition(all .3s ease); border-color: $lightGray2!important;
    }
  }
  &.hover-zoom-img{
    .cont-img{
      &:before{content: ''; z-index: 1; position: absolute; bottom:0; left:0; opacity: 1; @include bg-traslucid($white, .15); width: 100%; height: 100%; @include transition(all .7s ease-in-out);}
      img{@include transform(scale(1)); @include transition(transform 1s ease-in-out); z-index: 0;}
    }
    &:hover{
      .cont-img{
        &:before{@include bg-traslucid($white, .1); height: 100%; opacity: 0; @include transition(all .4s ease-in-out);}
        img{@include transform(scale(1.2)); @include transition(transform 1s ease-in-out);}
      }
    }
  }
  &.card-graphic{min-height: 380px;
    .card-header{flex: 1 1 auto;min-height: 100px;}
    //.card-body{padding-top: 100px!important;}
    .card-footer{min-height: 175px;}
    .chart{width: 100%;}
    &.graphic-backline{
      .cont-chart{
          //&:after{content:''; opacity: .2; z-index: 0; background: url('#{$BaseUrl}/backline.png'); background-repeat: repeat; position: absolute; width: 100%; height: 90%; left:0; top:0;}
      }
    }
  }
}


.cont{
  &-img{position: relative;padding-top: 100%; width: 100%; overflow: hidden; @include bg-traslucid($light, 100);
    &>img{position: absolute; left: 50%; top: 50%; @include transform(translate(-50%, -50%)); width: auto; height: 100%;}
    &.square{padding-top: 180px;
      &>img{width: auto; min-width: 100%; min-height: 100%;}
    }
    &.square2{padding-top: 57.983144551979368%;
      &>img{width: auto; min-width: 100%; min-height: 100%;}
    }
    &.bg-transparent{background: transparent!important;}
  }
  &-iframe{position: relative;padding-top: 39.00709219858156%; width: 100%; min-height: 470px; overflow: hidden; //550
    &>iframe{position: absolute; left: 50%; top: 50%; @include transform(translate(-50%, -50%)); width: 100%; height: 100%; border:0!important;}
  }
  &-chart{position: relative;padding-top: 40.880503144654088%; width: 100%; overflow: hidden; min-height:100px; //130
    &>div{position: absolute!important; left: 50%; top: 50%; @include transform(translate(-50%, -50%)); width: 100%; height: 100%; border:0!important;}
  }
}

.swiper-pagination{bottom:0; left:0; width: 100%; @extend %absoluteBox;
    &.disabled{display: none; opacity: 0;}
    .swiper-pagination-bullet{margin: 2px;box-shadow: none!important;
      outline: none!important;}
    .swiper-pagination-bullet-active{background: $dark!important;box-shadow: none!important;
      outline: none!important;}
    &.custom-primary{
      .swiper-pagination-bullet{margin: 2px 3px; width: 10px; height: 10px; position: relative; background: transparent; border: none!important; opacity: 1; @include transition(all .3s ease); //background: $lightPrimary; //border: 1px solid $primary;
        &:before{content: ''; position: absolute; bottom:0; left:0; opacity: 1;border: 1px solid $primary; width: 100%; height: 100%; @include border-radius(100%); @include transition(all .4s ease-in-out); z-index: 4;}
        &:after{content: ''; position: absolute; bottom: 50%; margin-bottom: -9px; margin-left: -9px; left: 50%; opacity: 0; @include bg-traslucid($lightPrimary, .7); width: 18px; height: 18px; @include border-radius(100%); @include transition(all .4s ease); z-index: 0;}
        &:hover, &:focus{
          &:after{opacity: 1;@include transition(all .3s ease);}
        }
      }
      .swiper-pagination-bullet-active {background: $primary!important; border-color: $primary;
        &:after{opacity: 0!important;}
      }
    }
}

.wrapper-news{position: relative; margin-top: -12vh;
  .cont-img{padding-top: 66.96969696969697%;/*221*/
    img{@include transform(scale(1)); @include transition(all .5s ease); top:0; bottom: auto; right: auto; left: auto;}
    &:hover{
      img{@include transform(scale(1.2)); @include transition(all .3s ease);}
    }
  }
  .swiper-container{
    //.swiper-pagination{width: 80px!important; left:50%; margin-left: -40px; }
  }
  .wrapper-controls{top: 10px;}
  .card{
    .btn{@include transition(all .4s ease)}
    &:hover{
      .btn{background-color: $primary; color: $white; border-color: $primary; @include transition(all .2s ease) }
    }
    .card-body{position: relative;
      &:before{content: ''; position: absolute; top:0; left:0; opacity: 1;@include bg-traslucid($light, 100); width: 100%; height: 100px; @include border-radius(100%); @include transition(all .4s ease-in-out); z-index: 2;}
      &>*{position: relative; z-index: 5;}
    }
    &:hover{
      .card-body{
        &:before{top:-20px; left:0;@include bg-traslucid($light, 100); @include border-radius(100%);}
      }
    }
  }
}

.wrapper-controls{
  .swiper-pagination{position: relative!important;left: auto; right: auto;}
  .swiper-button-prev{
    &:after{content: ''; background: url('#{$BaseUrl}/icons/chevron-left-solid.png');width: 15px;height: 15px;background-size: contain;background-repeat: no-repeat;}
  }
  .swiper-button-next{
    &:after{content: ''; background: url('#{$BaseUrl}/icons/chevron-right-solid.png');width: 15px;height: 15px;background-size: contain;background-repeat: no-repeat;}
  }
}


.wrapper-clientes{
  .cont-img{width: 240px; padding-top: 200px;}
}

.wrapper-proyects{
  ul{height: 500px; padding-right: 20px;}
}

perfect-scrollbar {
  &>.ps.ps--active-y > .ps__rail-y{width: 2px!important; @include bg-traslucid($white, .2); @include border-radius(10px);}
  &>.ps.ps--active-y > .ps__rail-y{margin-right: 5px!important;}
}

.ps__thumb-x, .ps__thumb-y {@include bg-traslucid($white, .8);}
.ps__thumb-y{right:  -2px!important;width: 6px!important;}
.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y {
  @include bg-traslucid($white, .9);
  width: 7px!important;
}

section{position: relative; @extend %relativeBox; padding-top: 5rem !important; padding-bottom: 5rem !important;
  &.intro{
    .wrapper-destacado{background-image: url('../assets/puente-de-la-mujer.jpg'); height: calc(100vh - 133px); background-size: cover; background-position: center center;background-attachment: fixed;
        &:before{content:''; position: absolute; width: 100%; height: 100%; top:0; left:0; @include bg-traslucid($black, .4);}
        .cont-info{position: relative; z-index: 10;}
    }
  }
  &.demos{
    .content-info{position: relative; z-index: 10;
      &:after{content: ''; position: absolute; width: 700px; height: 700px; right:0px; opacity: 0; top: 50%; margin-top: -350px; margin-right: -100px; @include bg-traslucid($dark, .9); @include border-radius(50%);@include transform(skew(0deg));@include transition(all .5s ease); z-index: 0;}
      &:before{content: ''; position: absolute; width: 700px; height: 700px; right:0; top: 50%; margin-top: -350px; margin-right: -100px; @include bg-traslucid($lightPrimary, 100); @include border-radius(100%); @include transition(all .5s ease);z-index: 1;}
      &>*{z-index: 5; position: relative;}
    }
    &:hover{
      .content-info{
        &:before{}
        &:after{@include bg-traslucid($primary, .10); @include transform(skew(5deg) translate(30px, 40px));@include border-radius(100%); opacity: 1;}

      }
    }
  }
  &.featured{
    .cont-img{padding-top: 200px;
      &:before{content: ''; position: absolute; animation: move 40s infinite alternate .3s; top:0; left:0; width: 100%; height: 100%; background-image: url('#{$BaseUrl}/featured/futuristic-smarth-city.jpg'); /*background-attachment: fixed;*/ background-size: cover; background-repeat: no-repeat; background-position: center bottom;}
    }
    .content-info{position: relative; z-index: 10;
      &:before{content: ''; position: absolute; width: 300px; height: 300px; left:0; top: 50%; margin-top: -150px; margin-left: -50px; @include bg-traslucid($white, 100); @include border-radius(100%); z-index: 0;}
      &>*{z-index: 1;}
    }
  }
  &.partners{
    .title{
      &-line-left{position: relative;
          &:before{content:''; right: 18px; top: 50%; margin-top: .5px; width: 20px; height: 1px; @include bg-traslucid($dark, 100); position: absolute; }
      }
    }
    .content-brands{
      .img-svg{width: 170px;}
    }
  }
  &.contacto{min-height: 100vh;
    .field-error{
      .form-control{border-color: $danger!important;}
    }

  }
}

@keyframes move{
  0%{opacity: 1;}
  100%{opacity: 1; background-position: 0, 30px;}
}

.modal{
  .modal-dialog {}
  .close{z-index: 100;position: absolute; top:12px; right: 15px;
    &>a{box-shadow: none!important; outline: none!important;}
  }
}

.swiper-pagination{
  &.hide{opacity: 0!important; visibility: hidden; display: none!important; }
}

.hide{display: none!important;}

//Elements Form
input{
  &::-ms-input-placeholder{color: $primary;} //Edge
}
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none!important; }
input{
  &.text-secondary{
    &::placeholder{color: rgba(0, 178, 177, 100)!important;}
  }
}

.ico-top{bottom: 15px; cursor: pointer!important; z-index: 500;width: 50px; height: 50px; right: 20px; display: none; opacity: 0; @include transition(all .3s ease-in-out);
  &.visible{display: block; opacity: 1;}
  a{width: 100%; height: 100%; display: block; overflow: hidden; @include transform(translate(0, 0)); @include transition(all .3s ease-in-out);
    svg{width: 100%;}
    img{width: 15px;}
  }
  &:hover{
    a{@include transform(translate(0, -12px));background-color: $lightPrimary!important;}
  }
}

//Pages
body{
  &>.modal-backdrop{z-index: 5500;}
}
.wrapperFull{position: relative; width:  100%; min-height: calc(100vh - #{$height-headers});
}

//Queries
//Min
@media screen and (max-width: 1440px){}

@media screen and (min-width: 1440px){
  .wrapper-proyects{
    ul{padding-right: 10%;}
  }
}

@media screen and (min-width: 1200px){}

@media screen and (min-width: 992px){}

@media screen and (min-width: 576px){}

//Max

@media screen and (max-width: 991px){
  .navbar-brand img {
    max-width: 150px;
  }

  header{
    .navbar-brand, .navbar-toggler{z-index: 1000; position: relative;}
    .navbar-collapse{width: 100%;}
    .navbar-nav{width: 100%; height: 100vh;top:0; left:0;left:0; position: fixed; background-color: $lightPrimary;
    }

  }

  section{
    &.demos{padding-bottom: 0!important;
      .content-info{
        &:before{width: 800px;height: 300px; right: 50%; top: auto; bottom: 0; margin-right: -400px; margin-top: 0; margin-bottom: -10%;}
      }
    }
  }
}

@media screen and (max-width: 767px){
	p, li{ font-size: 0.9375rem; line-height: 22px;}

  .ico-top{width: 38px; height: 38px;
    a{
      img{width: 12px;}
    }
    &:hover{
      a{@include transform(translate(0, -10px));}
    }
  }

  .cont-img{
    &>img {width: 100%;height: auto;}
    &.square{
      &>img{width: 100%; height: auto;}
    }
    &.square2{
      &> img {width: auto;min-width: auto;min-height: 100%;height: 100%;}
    }
  }
  body{
  }
  section{
    &.featured{//background-color: $primary!important;
      .content-info{//color: $white!important;
        &:before{display: none}
      }
    }
  }
  .wrapper-proyects{
    .btn-status{position: relative; margin-left: 50px!important;cursor: text!important;
      &:before{content: ''; position: absolute; height: 1px; width: 25px; @include bg-traslucid($white, .55); bottom: 50%; right: 100%; margin-bottom: -0.5px; margin-right: 10px;}
    }
    .btn-clients{border-radius: 5px!important;}
  }
}

@media screen and (max-width: 575px){
  section{
    &.partners{
      .title-line-left{
        &:after{content:''; left: 18px; top: 50%; margin-top: .5px; width: 20px; height: 1px; @include bg-traslucid($dark, 100); position: absolute; }
      }
    }
  }
}

@media screen and (max-width: 400px){}
