/*Typography*/
/*
@font-face {
  font-family: "SuecaSlab-Bold";
  src: url("../../fonts/SuecaSlab-Bold.woff") format("woff"),
    url("../../fonts/SuecaSlab-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
*/

//Base
$O: 'Open Sans', sans-serif;

//Clases y extensiones
%OBk {font-family: $O; font-weight: 800;}
%OB {font-family: $O; font-weight: 700;}
%OSB {font-family: $O; font-weight: 600;}
%OM {font-family: $O; font-weight: 500;}
%O {font-family: $O; font-weight: 400;}
%OL {font-family: $O; font-weight: 300;}


.font-OBk {@extend %OBk;}
.font-OB {@extend %OB;}
.font-OSB {@extend %OSB;}
.font-OM {@extend %OM;}
.font-O {@extend %O;}
.font-OL {@extend %OL;}
