
$primary: #1785aa;
$primary-op: rgba(23,133,170, .7);
$lightPrimary: #dcedf2; //lp
$darkPrimary: #6e7a8d;
$darkPrimary2:#005d9e;
$secondary: #cccccc; //gris claro

$lightSecondary: #eafbfb;
$darkSecondary: #0C8485;
$lightBlue: #e7f1f8;
$blue: #0074C4;
$green: #67c994;
$danger: #ec655f;

$light2: #fafafa;
$light: #f8f9fa;
$gray: #4A4A4A;
$gray2: #5a5a5a;
$dark-gray: #333333;
$borders: #dee2e6;
$lightGray: #eeeeee;
$lightGray2: #cccccc;

$lightGray3: #c9c9c9;
$lightGray4: #707070;
$lightGray5: #DFDFDF;

$danger: #dc3545;

$dark2: #1C1C1F;
$dark: #040404;
$black: #000000;
$white: #ffffff;
